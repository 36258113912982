<template>
  <div>
    <div class="d-flex flex-wrap align-items-stretch">
      <div class="col-lg-7 col-12 order-lg-1 min-vh-100 order-2 bg-white">
        <div class="d-flex justify-content-center p-5 m-4">
          <div class="col-md-6 col-12">
            <div class="text-center mt-3">
              <img
                src="/img/logo_1.png"
                alt="logo"
                width="200"
                class="text-center"
              />
            </div>
            <h3 class="text-center mt-5">LOGIN</h3>
            <form
              method="POST"
              action="javascript:"
              class="needs-validation"
              novalidate=""
              @submit.prevent
            >
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  tabindex="1"
                  autofocus
                  v-model="form.email"
                  autocomplete="off"
                  :class="{ 'is-invalid': errors.hasError('email') }"
                />
                <div class="invalid-feedback" v-if="errors.hasError('email')">
                  {{ errors.first("email") }}
                </div>
              </div>

              <div class="form-group">
                <div class="d-block">
                  <label for="password" class="control-label">Password</label>
                  <div class="float-right">
                    <a href="/manufacturer/forgot-password" class="text-small">
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <vue-password
                  v-model="form.password"
                  id="password"
                  name="password"
                  :disableStrength="true"
                  type="password"
                  autocomplete="off"
                  :class="{ 'is-invalid': errors.hasError('password') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasError('password')"
                >
                  {{ errors.first("password") }}
                </div>
              </div>

              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="remember"
                    class="custom-control-input"
                    id="remember-me"
                    v-model="form.remember"
                  />
                  <label class="custom-control-label" for="remember-me"
                    >Remember Me</label
                  >
                </div>
              </div>
              <div class="form-group">
                <template v-if="loading">
                  <button
                    type="button"
                    class="btn disabled btn-primary btn-progress btn-block"
                    data-dismiss="modal"
                    v-if="loading"
                  >
                    Loading...
                  </button>
                </template>
                <template v-else>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg btn-block"
                    @click="submit"
                  >
                    LOGIN
                  </button>
                </template>
              </div>

              <div class="mt-5 text-center">
                Don't have an account?
                <a href="/manufacturer/register">Create new one</a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="
          col-lg-5 col-12
          order-lg-2 order-1
          min-vh-100
          background-walk-y
          position-relative
          overlay-gradient-bottom
        "
        data-background="/stisla/img/unsplash/login-bg.jpg"
      ></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapState, mapActions } = createNamespacedHelpers("login");
const { mapFields } = createHelpers({
  getterType: "login/getField",
  mutationType: "login/updateField",
});

export default {
  computed: {
    ...mapState(["loading", "errors", "resMessage"]),
    ...mapFields(["form"]),
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.login("/manufacturer/login");
    },
  },

  watch: {
    resMessage(value) {
      if (!_.isEmpty(value)) {
        if (value.code == 200) {
          this.$toast.success(value.message, "OK", this.toast);
          setTimeout(function () {
            window.location.href = "/manufacturer";
          }, 1000);
        } else if (value.code == 422) {
          this.$toast.warning(
            value.message,
            _.toString(value.code),
            this.toast
          );
        } else {
          this.$toast.error(value.message, _.toString(value.code), this.toast);
        }
      }
    },
  },
};
</script>
