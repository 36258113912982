import Vue from "vue";
import Swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(Swal);
Vue.prototype.$swal = Swal;

export default {
  data() {
    return {
      toast: {
        position: "topRight",
      },
    };
  },
  methods: {
    successNotif: (msg) => Vue.swal("Success", msg, "success"),
    errorNotif: (msg, errors = null) => {
      Vue.swal({
        icon: "error",
        title: "Oops..",
        text: msg,
        footer: errors,
      });
    },
  },
};
