import ErrorBag from "@/helpers/error-bag";
import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";

const manufacturerRegistration = {
  namespaced: true,
  state: {
    loading: false,
    errors: new ErrorBag(),
    resMessage: null,
    userType: "company",
    companyTypes: [],
    register: {
      name: null,
      email: null,
      country_code: null,
      phone_code: null,
      phone_number: null,
      password: null,
      password_confirmation: null,
      company: {
        name: null,
        type: null,
        purpose: null,
      },
    },
  },
  getters: {
    getField,
    phoneNumber: (state) => state.register.phone_number,
  },
  mutations: {
    updateField,
    setCompanyTypes: (state, companyTypes) =>
      (state.companyTypes = companyTypes),
    setCountryCode: (state, countryCode) =>
      (state.register.country_code = countryCode),
    setPhoneCode: (state, phoneCode) => (state.register.phone_code = phoneCode),
    setResMessage: (state, response) => {
      state.resMessage = {
        code: response.code,
        message: response.title,
      };
    },
    setErrors: (state, errors) => {
      const error = errors.response.data.error;
      state.errors.setErrors(error.errors);
      state.resMessage = {
        code: error.code,
        message: error.title,
      };
    },
  },
  actions: {
    loadCompanyTypes: async ({ commit }) => {
      const companyTypes = await axios
        .get("/api/company-types")
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err);
        });

      if (companyTypes) {
        commit("setCompanyTypes", companyTypes);
      }
    },

    setCompanyType: ({ commit }, value) => commit("setCompanyType", value),

    submit: async ({ commit, state }) => {
      if (state.errors.hasErrors()) {
        state.errors.clearAll();
      }

      state.loading = true;
      const form = new FormData();

      form.append("user_type", state.userType);
      _.forIn(state.register, function (value, key) {
        if (key === "company") {
          if (state.userType == "company") {
            _.forIn(value, function (val, key) {
              if (!_.isEmpty(val)) {
                if (key === "type") {
                  form.append(`company[${key}]`, val.id);
                } else {
                  form.append(`company[${key}]`, val);
                }
              }
            });
          }
        } else {
          if (!_.isEmpty(value)) {
            form.append(key, value);
          }
        }
      });

      await axios
        .post("/manufacturer/register", form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          state.loading = false;
          commit("setResMessage", { code: 200, title: "Register success" });
        })
        .catch((err) => {
          state.loading = false;
          state.register.password = null;
          state.register.password_confirmation = null;
          commit("setErrors", err);
        });
    },
  },
};

export default manufacturerRegistration;
