import _ from "lodash";

const objToForm = (object, form, namespaced = null) => {
  let formData = form || new FormData();
  let formKey;

  if (!object || (!Array.isArray(object) && typeof object !== "object")) {
    throw new Error("Please provide valid inputs");
  }

  _.forIn(object, (obj, key) => {
    if (namespaced) {
      formKey = `${namespaced}[${key}]`;
    } else {
      formKey = key;
    }

    if (_.isObject(obj)) {
      objToForm(obj, formData, formKey);
    } else {
      if (_.isNumber(obj)) {
        formData.append(formKey, obj);
      } else if (_.isBoolean(obj)) {
        formData.append(formKey, obj);
      } else {
        formData.append(formKey, obj == "null" || obj == null ? "" : obj);
      }
    }
  });

  return formData;
};

export default objToForm;
