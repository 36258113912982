import Vue from "vue";
import vuex from "vuex";
import founder from "./modules/founder";
import login from "./modules/login";
import manufacturer from "./modules/manufacturer";
import manufacturerRegistration from "./modules/manufacturer-registration";
import property from "./modules/property";
import ErrorBag from "@/helpers/error-bag";
import Swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import _ from "lodash";
import country from "./modules/country";
import companyType from "./modules/company-type";
import axios from "axios";
import contactUs from "./modules/contact-us";
import product from "./modules/product";
import resetPassword from "./modules/reset-password";
import user from "./modules/user";
import notification from "./modules/notification";
import revision from "./modules/revision";
import blog from "./modules/blogs";
import aboutUs from "./modules/about-us";
import privacyPolicy from "./modules/privacy-policy";
import admin from "./modules/admin";
import manufacturerProfile from "./modules/manufacturer-profile";
import requestTags from "./modules/request-tags";

Vue.use(vuex);
Vue.use(Swal);

const store = new vuex.Store({
  modules: {
    login: login,
    admin: admin,
    manufacturerProfile: manufacturerProfile,
    manufacturerRegistration: manufacturerRegistration,
    manufacturer: manufacturer,
    founder: founder,
    country: country,
    companyType: companyType,
    contactUs: contactUs,
    property: property,
    product: product,
    resetPassword: resetPassword,
    user: user,
    notification: notification,
    revision: revision,
    blog: blog,
    aboutUs: aboutUs,
    privacyPolicy: privacyPolicy,
    requestTags: requestTags,
  },
  state: {
    loading: false,
    search: "",
    editing: false,
    refresh: false,
    waitingList: false,
    isAdmin: false,
    isVisibile: false,
    isDraft: false,
    errors: new ErrorBag(),
    resMessage: null,
    imageResolution: {
      founder: {
        profile_picture: {
          w: 412,
          h: 412,
        },
      },
      profile_picture: {
        w: 412,
        h: 412,
      },
      manufacturer: {
        image: {
          w: 818,
          h: 500,
        },
      },
      blog: {
        image: {
          w: 818,
          h: 500,
        },
      },
      aboutUs: {
        image: {
          w: 818,
          h: 500,
        },
      },
    },
    images: {},
  },
  getters: {
    getSearch: (state) => state.search,
  },
  mutations: {
    setImages: (state, { image, field }) => _.set(state.images, field, image),
    setTranslations: (state, { modules, translations }) => {
      _.forEach(modules, (module) => {
        _.set(state[module], "translation", translations);
      });
    },
    setState: (state, { module, data }) => {
      _.forIn(data, (obj, key) => {
        state[module][key] = obj;
      });
    },
    setSearch: (state, value) => (state.search = value),
    setResMessage: (state, response) => {
      state.resMessage = {
        code: response.code,
        message: response.title,
        forceMessage: response.forceMessage ?? false,
      };
    },
    setErrors: (state, errors) => {
      const error = errors.response.data.error;
      state.errors.setErrors(error.errors);
      state.resMessage = {
        code: error.code,
        message: error.title,
      };
    },
    setWaitingList: (state, waitingList) => (state.waitingList = waitingList),
    setIsAdmin: (state, isAdmin) => (state.isAdmin = isAdmin),
    setIsVisible: (state, isVisibile) => (state.isVisibile = isVisibile),
    setEditing: (state, isEdit) => (state.editing = isEdit),
    setIsDraft: (state, isDraft) => (state.isDraft = isDraft),
    removeImage: (state, { index, field }) =>
      state.images[field].splice(index, 1),
  },
  actions: {
    setImage: ({ commit }, { image, field }) => {
      if (image !== undefined) {
        commit("setImages", { image, field });
      }
    },
    delete: ({ commit, state }, url) => {
      Vue.swal
        .fire({
          title: "Are you sure want to delete this data?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#dd0b32",
        })
        .then((res) => {
          if (res.isConfirmed) {
            axios
              .delete(url)
              .then((res) => {
                commit(
                  "setResMessage",
                  { code: 200, title: "Data has been deleted!" },
                  { root: true }
                );
                state.refresh = true;
              })
              .catch((err) => {
                commit("setErrors", err);
              });
          }
        });
    },
    deleteImage: ({ commit, state, dispatch }, { index, field, module }) => {
      Vue.swal
        .fire({
          title: "Are you sure want to delete this data?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#dd0b32",
        })
        .then((res) => {
          if (res.isConfirmed) {
            commit("removeImage", { index, field });
            dispatch(`${module}/destroyImage`, index, { root: true });
          }
        });
    },
  },
});

export default store;
