import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";

const revision = {
  namespaced: true,
  state: {
    endpoint: "",
    message: "",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateEndpoint: (state, url) => (state.endpoint = url),
    updateMessage: (state) => (state.message = ""),
  },
  actions: {
    setUrl: ({ commit }, url) => {
      commit("updateEndpoint", url);
    },
    sendRevision: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;

      let form = new FormData();
      form.append("_method", "PATCH");
      form.append("message", state.message);

      await axios
        .post(state.endpoint, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Message saved!!!" },
            { root: true }
          );
          commit("updateMessage");
          $("#updateMessage").modal("hide");
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default revision;
