import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";

const founder = {
  namespaced: true,
  state: {
    id: null,
    name: null,
    translation: {},
    properties: {},
    profile_picture: null,
  },
  getters: {
    getField,
    getFounderPicture: (state) => state.profile_picture,
  },
  mutations: {
    updateField,
  },
  actions: {},
};

export default founder;
