<template>
  <div class="container mt-5">
    <div class="row">
      <div
        class="
          col-12 col-sm-8
          offset-sm-2
          col-md-6
          offset-md-3
          col-lg-6
          offset-lg-3
          col-xl-4
          offset-xl-4
        "
      >
        <div class="login-brand">
          <img
            src="/img/logo_1.png"
            alt="logo"
            width="200"
            class="text-center"
          />
        </div>

        <div class="card card-primary">
          <div class="card-header">
            <h4>Reset Password</h4>
          </div>

          <div class="card-body">
            <form
              method="POST"
              action="javascript:"
              class="needs-validation"
              novalidate=""
              @submit.prevent
            >
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  type="email"
                  v-model="email"
                  :class="{ 'is-invalid': errors.hasError('email') }"
                  class="form-control"
                  name="email"
                  tabindex="1"
                  required
                  autofocus
                />
                <div class="invalid-feedback" v-if="errors.hasError('email')">
                  {{ errors.first("email") }}
                </div>
              </div>

              <div class="form-group">
                <label for="password">New Password</label>
                <vue-password
                  v-model="password"
                  id="password"
                  name="password"
                  :disableStrength="true"
                  type="password"
                  autocomplete="off"
                  :class="{ 'is-invalid': errors.hasError('password') }"
                />
                <div
                  class="invalid-feedback"
                  v-if="errors.hasError('password')"
                >
                  {{ errors.first("password") }}
                </div>
              </div>

              <div class="form-group">
                <label for="password-confirm">Confirm Password</label>
                <vue-password
                  v-model="password_confirmation"
                  id="password_confirmation"
                  name="password_confirmation"
                  :disableStrength="true"
                  type="password"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <template v-if="loading">
                  <button
                    type="button"
                    class="btn disabled btn-primary btn-progress btn-block"
                    data-dismiss="modal"
                    v-if="loading"
                  >
                    Loading...
                  </button>
                </template>
                <template v-else>
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg btn-block"
                    @click="submit"
                  >
                    Reset Password
                  </button>
                </template>
              </div>
            </form>
          </div>
        </div>
        <div class="simple-footer">Copyright &copy; Stisla 2018</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "getField",
  mutationType: "updateField",
});

export default {
  props: ["token"],
  created() {
    this.setToken(this.token);
  },
  computed: {
    ...mapState(["loading", "errors", "resMessage"]),
    ...mapFields("resetPassword", [
      "email",
      "password",
      "password_confirmation",
    ]),
  },
  methods: {
    ...mapActions("resetPassword", ["setToken", "reset"]),
    submit() {
      this.reset("/manufacturer/reset-password");
    },
  },

  watch: {
    resMessage(value) {
      if (!_.isEmpty(value)) {
        if (value.code == 200) {
          this.$toast.success(value.message, "OK", this.toast);
          setTimeout(function () {
            window.location.href = "/manufacturer";
          }, 1000);
        } else if (value.code == 422) {
          this.$toast.warning(
            value.message,
            _.toString(value.code),
            this.toast
          );
        } else {
          this.$toast.error(value.message, _.toString(value.code), this.toast);
        }
      }
    },
  },
};
</script>
