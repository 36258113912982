import PaginationResource from "../../models/PaginationResource";
import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";

const aboutUs = {
  namespaced: true,
  state: {
    aboutUsSections: new PaginationResource(),
    id: null,
    section: null,
    caption: null,
    image: null,
    translation: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateList: (state, list) => state.aboutUsSections.setResource(list),
    updateId: (state, id) => (state.id = id),
    updateSection: (state, section) => (state.section = section),
    updateCaption: (state, caption) => (state.caption = caption),
    updateImage: (state, image) => (state.image = image),
    updateTranslations: (state, translations) =>
      (state.translation = translations),
  },
  actions: {
    loadSections: async ({ commit, rootState }, page = 1) => {
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const list = await axios
        .get("/admin/about-us/list", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateList", list);
      }
    },
    setAboutUs: ({ commit }, data) => {
      commit("updateId", data.id);
      commit("updateSection", data.section);
      commit("updateCaption", data.caption);
      commit("updateImage", data.image);
      commit("updateTranslations", _.keyBy(data.translations, "locale"));
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }

      rootState.loading = true;

      let url = `/admin/about-us/${state.id}`;
      let form = new FormData();
      form.append("_method", "PATCH");

      const blog = _.omit(state, ["aboutUsSections", "id", "image"]);
      form = objectToForm(blog, form);

      if (!_.isEmpty(rootState.images)) {
        form = objectToForm(rootState.images, form);
      }

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data;
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "About Us saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default aboutUs;
