import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import PaginationResource from "../../models/PaginationResource";

const product = {
  namespaced: true,
  state: {
    id: null,
    name: null,
    batch: null,
    currency: null,
    price: null,
    country: null,
    photos: [],
    properties: {},
    translation: {},
    manufacturerId: null,
    products: new PaginationResource(),
    countries: new PaginationResource(),
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateProductcs: (state, list) => state.products.setResource(list),
    updateCountires: (state, list) => state.countries.setResource(list),
    updateManufacturer: (state, manufacturer) =>
      (state.manufacturerId = manufacturer),
    setCurrency: (state, currency) => (state.currency = currency),
    removeProductImage: (state, index) => state.photos.splice(index, 1),
  },
  actions: {
    setManufacturer: ({ commit }, manufacturer) => {
      commit("updateManufacturer", manufacturer);
    },
    loadProducts: async ({ commit, rootState, state }, page = 1) => {
      rootState.refresh = false;
      const baseUrl = rootState.isAdmin ? "/admin" : "/manufacturer";
      let url = "";

      if (_.isNull(state.manufacturerId)) {
        url = rootState.waitingList
          ? `${baseUrl}/request-products/list`
          : `${baseUrl}/products/list`;
      } else {
        url = rootState.waitingList
          ? `${baseUrl}/manufacturers/${state.manufacturerId}/product-requests/list`
          : `${baseUrl}/manufacturers/${state.manufacturerId}/products/list`;
      }

      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const products = await axios
        .get(url, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(products)) {
        commit("updateProductcs", products);
      }
    },
    loadCountries: async ({ commit, rootState }, query = null) => {
      const params = {};

      if (!_.isEmpty(query)) {
        rootState.loading = true;
        params.q = query;
      }

      const countries = await axios
        .get("/api/countries", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(countries)) {
        rootState.loading = false;
        commit("updateCountires", countries);
      }
    },
    loadProduct: async ({ commit, rootState, state, dispatch }, id) => {
      let url = "";

      if (rootState.isAdmin) {
        if (!_.isNull(state.manufacturerId)) {
          url = rootState.waitingList
            ? `/admin/request-products/${id}/detail`
            : `/admin/manufacturers/${state.manufacturerId}/products/${id}/detail`;
        } else {
          url = `/admin/request-products/${id}/detail`;
        }
      } else {
        url = `/manufacturer/request-products/${id}`;
      }

      rootState.refresh = false;
      const product = await axios
        .get(url)
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(product)) {
        dispatch("setData", product);
      }
    },
    setData: ({ commit }, data) => {
      commit("setState", { module: "product", data: data }, { root: true });
    },
    destroyImage: ({ commit, state }, index) => {
      commit("removeProductImage", index);
    },
    submit: ({ commit, state, rootState, dispatch }) => {
      if (!_.isEmpty(rootState.images.products)) {
        let url = "";
        let form = new FormData();
        rootState.loading = true;

        if (rootState.errors.hasErrors()) {
          rootState.errors.clearAll();
        }

        if (rootState.isAdmin) {
          url = `/admin/request-products/${state.id}/approve`;
        } else {
          url = "/manufacturer/request-products";
        }

        const product = _.omit(state, [
          "id",
          "photos",
          "products",
          "countries",
          "manufacturer",
        ]);

        form = objectToForm(product, form);

        if (!_.isEmpty(_.toString(state.id))) {
          if (!rootState.isAdmin) {
            url = `${url}/${state.id}`;
          }

          form.append("_method", "PATCH");
        }

        axios
          .post(url, form, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            const data = res.data.data;

            if (!_.isEmpty(data)) {
              dispatch("setData", data);
            }

            if (!_.isEmpty(rootState.images.products)) {
              dispatch("uploadPhotos");
            } else {
              rootState.loading = false;
              commit(
                "setResMessage",
                { code: 200, title: "Product saved" },
                { root: true }
              );
            }
          })
          .catch((err) => {
            rootState.loading = false;
            commit("setErrors", err, { root: true });
          });
      } else {
        commit(
          "setResMessage",
          { code: 400, title: "Pleased upload minimum 1 image" },
          { root: true }
        );
      }
    },
    uploadPhotos: ({ commit, state, rootState, dispatch }) => {
      const baseUrl = rootState.isAdmin ? "/admin" : "/manufacturer";
      const url = `${baseUrl}/request-products/${state.id}/upload-images`;

      const form = objectToForm(
        rootState.images.products,
        new FormData(),
        "images"
      );

      axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;

          commit(
            "setResMessage",
            { code: 200, title: "Product Info saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default product;
