import { cloneDeep } from "lodash";

const PaginationResource = class {
    constructor() {
        Object.assign(
            this,
            cloneDeep({
                data: [],
                links: {
                    first: null,
                    last: null,
                    next: null,
                    prev: null,
                },
                meta: {
                    current_page: 1,
                    last_page: 1,
                    total: 0,
                    from: null,
                    to: null,
                    path: "",
                },
            })
        );
    }

    setResource(resource) {
        Object.assign(this, cloneDeep(resource));
    }

    isEmpty() {
        return this.data.length < 1;
    }

    currentPage() {
        return this.meta.current_page;
    }

    lastPage() {
        return this.meta.last_page;
    }

    hasPages() {
        return this.currentPage() != 1 || this.hasMorePages();
    }

    hasMorePages() {
        return this.currentPage() < this.lastPage();
    }
};

export default PaginationResource;
