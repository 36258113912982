<template>
  <div>
    <div class="d-flex flex-wrap align-items-stretch">
      <div class="col-lg-7 col-12 order-lg-1 min-vh-100 order-2 bg-white">
        <div class="d-flex justify-content-center p-3 m-2">
          <div class="col-10">
            <div class="text-center mt-3">
              <img
                src="/img/logo_1.png"
                alt="logo"
                width="100"
                class="text-center"
              />
            </div>
            <h3 class="text-center mt-3">REGISTER</h3>
            <h4 class="text-center mt-5">SELECT USER TYPE</h4>
            <form
              method="POST"
              action="#"
              class="needs-validation"
              novalidate=""
              @submit.prevent
            >
              <div class="row d-flex justify-content-center">
                <div class="form-group col-md-4">
                  <div class="selectgroup w-100">
                    <label class="selectgroup-item">
                      <input
                        type="radio"
                        name="value"
                        value="company"
                        class="selectgroup-input"
                        v-model="userType"
                      />
                      <span class="selectgroup-button">As Company</span>
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-4">
                  <div class="selectgroup w-100">
                    <label class="selectgroup-item">
                      <input
                        type="radio"
                        name="value"
                        value="person"
                        class="selectgroup-input"
                        v-model="userType"
                      />
                      <span class="selectgroup-button">As Private Person</span>
                    </label>
                  </div>
                </div>
              </div>
              <template v-if="userType == 'company'">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="company-name" class="required"
                      >Company Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="register.company.name"
                      :class="{ 'is-invalid': errors.hasError('company.name') }"
                      placeholder="company name"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasError('company.name')"
                    >
                      {{ errors.first("company.name") }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="company-type" class="required"
                      >Company Type</label
                    >
                    <multiselect
                      v-model="register.company.type"
                      :options="companyTypes"
                      :custom-label="optionName"
                      :class="{ 'is-invalid': errors.hasError('company.type') }"
                      placeholder="Select company type"
                      label="companyType"
                      track-by="id"
                    ></multiselect>
                    <div
                      class="invalid-feedback"
                      v-if="errors.hasError('company.type')"
                    >
                      {{ errors.first("company.type") }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="company-purpose">Company purpose</label>
                    <textarea
                      class="form-control"
                      name="company.purpose"
                      v-model="register.company.purpose"
                      id="companyPurpose"
                      data-height="150"
                    ></textarea>
                    <div class="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                </div>
              </template>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="name" class="required">Name</label>
                  <input
                    type="name"
                    v-model="register.name"
                    class="form-control"
                    :class="{ 'is-invalid': errors.hasError('name') }"
                    placeholder="Name"
                  />
                  <div class="invalid-feedback" v-if="errors.hasError('name')">
                    {{ errors.first("name") }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="email" class="required">Email</label>
                  <input
                    type="email"
                    v-model="register.email"
                    class="form-control"
                    :class="{ 'is-invalid': errors.hasError('email') }"
                    placeholder="Email"
                  />
                  <div class="invalid-feedback" v-if="errors.hasError('email')">
                    {{ errors.first("email") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="phone-number" class="required"
                    >Phone Number</label
                  >
                  <vue-tel-input
                    v-model="register.phone_number"
                    :auto-default-country="false"
                    :custom-validate="/^[0-9]*$/"
                    defaultCountry="ID"
                    id="phone"
                    name="phone"
                    @country-changed="onChangePhoneCode"
                    :class="{ 'is-invalid': errors.hasError('phone_number') }"
                    class="form-control"
                  ></vue-tel-input>
                  <div
                    class="invalid-feedback"
                    v-if="errors.hasError('phone_number')"
                  >
                    {{ errors.first("phone_number") }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="password" class="required">Password</label>
                  <vue-password
                    v-model="register.password"
                    id="password"
                    name="password"
                    :disableStrength="true"
                    :class="{ 'is-invalid': errors.hasError('password') }"
                    type="password"
                    autocomplete="off"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="errors.hasError('password')"
                  >
                    {{ errors.first("password") }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="password_confirmation" class="required"
                    >Re-type Password</label
                  >
                  <vue-password
                    v-model="register.password_confirmation"
                    id="password_confirmation"
                    name="password_confirmation"
                    :disableStrength="true"
                    type="password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group">
                <template v-if="loading">
                  <button
                    type="button"
                    class="btn disabled btn-primary btn-progress btn-block"
                    data-dismiss="modal"
                    v-if="loading"
                  >
                    Loading...
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    @click="submit"
                  >
                    REGISTER
                  </button>
                </template>
              </div>

              <div class="mt-5 text-center">
                Already have account?
                <a href="/manufacturer/login">Login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="
          col-lg-5 col-12
          order-lg-2 order-1
          min-vh-100
          background-walk-y
          position-relative
          overlay-gradient-bottom
        "
        data-background="/stisla/img/unsplash/login-bg.jpg"
      ></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  "manufacturerRegistration"
);
const { mapFields } = createHelpers({
  getterType: "manufacturerRegistration/getField",
  mutationType: "manufacturerRegistration/updateField",
});

export default {
  created() {
    this.loadCompanyTypes();
  },

  computed: {
    ...mapState(["companyTypes", "loading", "errors", "resMessage"]),
    ...mapFields(["userType", "register"]),
  },

  methods: {
    ...mapMutations(["setCountryCode", "setPhoneCode"]),
    ...mapActions(["loadCompanyTypes", "submit"]),
    onChangePhoneCode(payload) {
      this.setCountryCode(payload.iso2);
      this.setPhoneCode(payload.dialCode);
    },
    optionName({ id, name }) {
      return name;
    },
  },

  watch: {
    resMessage(value) {
      if (!_.isEmpty(value)) {
        if (value.code == 200) {
          this.$toast.success(value.message, "OK", this.toast);
          setTimeout(function () {
            window.location.href = "/manufacturer";
          }, 1000);
        } else if (value.code == 422) {
          this.$toast.warning(
            value.message,
            _.toString(value.code),
            this.toast
          );
        } else {
          this.$toast.error(value.message, _.toString(value.code), this.toast);
        }
      }
    },
  },
};
</script>
