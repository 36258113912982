<template>
  <div>
    <div class="container">
      <div class="row">
        <div
          class="
            col-12 col-sm-8
            offset-sm-2
            col-md-6
            offset-md-3
            col-lg-6
            offset-lg-3
            col-xl-4
            offset-xl-4
          "
        >
          <div class="login-brand">
            <img src="/img/logo_1.png" alt="logo" width="130" />
          </div>

          <div class="card card-primary">
            <div class="card-header">
              <h4>Login</h4>
            </div>

            <div class="card-body">
              <form
                method="POST"
                action="#"
                class="needs-validation"
                novalidate=""
              >
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    required
                    autofocus
                    v-model="form.email"
                    :class="{ 'is-invalid': errors.hasError('email') }"
                  />
                  <div class="invalid-feedback" v-if="errors.hasError('email')">
                    {{ errors.first("email") }}
                  </div>
                </div>

                <div class="form-group">
                  <div class="d-block">
                    <label for="password" class="control-label">Password</label>
                  </div>
                  <vue-password
                    v-model="form.password"
                    id="password"
                    name="password"
                    :disableStrength="true"
                    type="password"
                    :class="{ 'is-invalid': errors.hasError('password') }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="errors.hasError('password')"
                  >
                    {{ errors.first("password") }}
                  </div>
                </div>

                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="remember"
                      class="custom-control-input"
                      id="remember-me"
                      v-model="form.remember"
                    />
                    <label class="custom-control-label" for="remember-me"
                      >Remember Me</label
                    >
                  </div>
                </div>

                <div class="form-group">
                  <template v-if="loading">
                    <button
                      type="button"
                      class="btn disabled btn-primary btn-progress btn-block"
                      data-dismiss="modal"
                      v-if="loading"
                    >
                      Loading...
                    </button>
                  </template>
                  <template v-else>
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg btn-block"
                      @click="submit"
                    >
                      LOGIN
                    </button>
                  </template>
                </div>
              </form>
            </div>
          </div>
          <div class="simple-footer">Copyright &copy; Timedoor 2022</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapState, mapActions } = createNamespacedHelpers("login");
const { mapFields } = createHelpers({
  getterType: "login/getField",
  mutationType: "login/updateField",
});

export default {
  computed: {
    ...mapState(["loading", "errors", "resMessage"]),
    ...mapFields(["form"]),
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.login("/admin/login");
    },
  },

  watch: {
    resMessage(value) {
      if (!_.isEmpty(value)) {
        if (value.code == 200) {
          this.$toast.success(value.message, "OK", this.toast);
          setTimeout(function () {
            window.location.href = "/admin";
          }, 1000);
        } else if (value.code == 422) {
          this.$toast.warning(
            value.message,
            _.toString(value.code),
            this.toast
          );
        } else {
          this.$toast.error(value.message, _.toString(value.code), this.toast);
        }
      }
    },
  },
};
</script>
