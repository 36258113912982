import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";

const manufacturerProfile = {
  namespaced: true,
  state: {
    user_type: "manufacturer_company",
    companyTypes: [],
    name: null,
    email: null,
    country_code: null,
    phone_code: null,
    phone_number: null,
    old_password: null,
    password: null,
    password_confirmation: null,
    profile_picture: null,
    company: {
      name: null,
      type: null,
      purpose: null,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setCompanyTypes: (state, companyTypes) =>
      (state.companyTypes = companyTypes),
    updateUser: (state, data) => {
      state.user_type =
        data.type == "manufacturer_company" ? "company" : "person";
      state.name = data.name;
      state.email = data.email;
      state.country_code = data.country_code;
      state.phone_code = data.phone_code;
      state.phone_number = data.phone_number.toString();
      state.profile_picture = data.profile_picture;

      if (data.type == "manufacturer_company") {
        state.company = {
          name: data.user_company.name,
          type: data.user_company.company_type,
          purpose: data.user_company.purpose,
        };
      }
    },
    setCountryCode: (state, countryCode) => (state.country_code = countryCode),
    setPhoneCode: (state, phoneCode) => (state.phone_code = phoneCode),
  },
  actions: {
    loadCompanyTypes: async ({ commit }) => {
      const companyTypes = await axios
        .get("/api/company-types")
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err);
        });

      if (companyTypes) {
        commit("setCompanyTypes", companyTypes);
      }
    },
    setUser: ({ commit }, data) => {
      commit("updateUser", data);
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;
      let url = "/manufacturer/profile";
      let form = new FormData();
      let admin = _.omit(state, ["profile_picture", "companyTypes", "company"]);
      if (state.password == null) {
        admin = _.omit(admin, [
          "password",
          "old_password",
          "password_confirmation",
        ]);
      }

      form = objectToForm(admin, form);
      form.append("_method", "PATCH");
      if (state.user_type == "company") {
        _.forIn(state.company, function (val, key) {
          if (val !== null) {
            if (key === "type") {
              form.append(`company[${key}]`, val.id);
            } else {
              form.append(`company[${key}]`, val);
            }
          }
        });
      }

      if (!_.isEmpty(rootState.images)) {
        form = objectToForm(rootState.images, form);
      }

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data;
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Profile saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default manufacturerProfile;
