import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";

const resetPassword = {
  namespaced: true,
  state: {
    token: null,
    email: null,
    password: null,
    password_confirmation: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateToken: (state, token) => (state.token = token),
  },
  actions: {
    setToken: ({ commit }, token) => {
      commit("updateToken", token);
    },
    reset: async ({ commit, state, rootState }, url) => {
      let form = new FormData();
      rootState.loading = true;

      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }

      form = objectToForm(state, form);

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Password successfu" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          state.password = null;
          state.password_confirmation = null;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default resetPassword;
