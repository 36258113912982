import PaginationResource from "../../models/PaginationResource";
import { getField, updateField } from "vuex-map-fields";

const user = {
  namespaced: true,
  state: {
    listUser: new PaginationResource(),
    listOwnedProduct: new PaginationResource(),
    listLovedProduct: new PaginationResource(),
    user: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateList: (state, list) => state.listUser.setResource(list),
    updateOwnedProduct: (state, list) =>
      state.listOwnedProduct.setResource(list),
    updateLovedProduct: (state, list) =>
      state.listLovedProduct.setResource(list),
    updateUser: (state, data) => (state.user = data),
  },
  actions: {
    loadListUser: async ({ commit, rootState }, page = 1) => {
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const list = await axios
        .get("/admin/users/list", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateList", list);
      }
    },
    loadOwnedProduct: async ({ commit, state }, page = 1) => {
      const params = {
        page: page,
      };

      const list = await axios
        .get(`/admin/users/${state.user.id}/owned-product`, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateOwnedProduct", list);
      }
    },
    loadLovedProduct: async ({ commit, state }, page = 1) => {
      const params = {
        page: page,
      };

      const list = await axios
        .get(`/admin/users/${state.user.id}/wishlist-product`, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateLovedProduct", list);
      }
    },
    setUser: ({ commit }, data) => {
      commit("updateUser", data);
    },
  },
};

export default user;
