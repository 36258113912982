import PaginationResource from "../../models/PaginationResource";
import { getField, updateField } from "vuex-map-fields";

const contactUs = {
  namespaced: true,
  state: {
    listContactUs: new PaginationResource(),
    contactUs: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateList: (state, list) => state.listContactUs.setResource(list),
    updateContactUs: (state, data) => (state.contactUs = data),
    setPhoneNumber: (state, data) =>
      (state.contactUs.phone = `+${data.phone_code}${data.phone_number}`),
  },
  actions: {
    loadListContactUs: async ({ commit, rootState }, page = 1) => {
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const list = await axios
        .get("/admin/contact-us/list", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateList", list);
      }
    },
    setData: ({ commit }, data) => {
      commit("updateContactUs", data);
      commit("setPhoneNumber", data);
    },
  },
};

export default contactUs;
