import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";

const admin = {
  namespaced: true,
  state: {
    name: null,
    email: null,
    old_password: null,
    password: null,
    password_confirmation: null,
    profile_picture: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateUser: (state, data) => {
      state.name = data.name;
      state.email = data.email;
      state.profile_picture = data.profile_picture;
    },
  },
  actions: {
    setUser: ({ commit }, data) => {
      commit("updateUser", data);
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;
      let url = "/admin/profile";
      let form = new FormData();
      let admin = _.omit(state, ["profile_picture"]);
      if (state.password == null) {
        admin = _.omit(admin, [
          "password",
          "old_password",
          "password_confirmation",
        ]);
      }

      form = objectToForm(admin, form);
      form.append("_method", "PATCH");

      if (!_.isEmpty(rootState.images)) {
        form = objectToForm(rootState.images, form);
      }

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data;
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Profile saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default admin;
