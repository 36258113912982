/**
 * Auth
 */

require("./bootstrap");
import Vue from "vue";
import VueIziToast from "vue-izitoast";
import store from "@/store/store";
import Multiselect from "./mutiselect";
import VueTelInput from "./vue-tel-input";
import VuePassword from "./vue-password";
import ManufacturereRegister from "./components/manufacturer/auth/RegisterPage";
import ManufacturerLogin from "./components/manufacturer/auth/LoginPage";
import AdminLogin from "./components/admin/auth/LoginPage";
import ManufacturerResetPassword from "./components/manufacturer/auth/ResetPassword";
import notification from "./mixins/notification";
import "izitoast/dist/css/iziToast.min.css";

window.Vue = require("vue").default;

Vue.use(VueIziToast);
Vue.use(VueTelInput);

Vue.component("multiselect", Multiselect);
Vue.component("vue-tel-input", VueTelInput);
Vue.component("vue-password", VuePassword);
Vue.component("manufacturer-register", ManufacturereRegister);
Vue.component("manufacturer-reset-password", ManufacturerResetPassword);
Vue.component("manufacturer-login", ManufacturerLogin);
Vue.component("admin-login", AdminLogin);

Vue.mixin(notification);

const app = new Vue({
  el: "#app",
  store,
});
