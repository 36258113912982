import ErrorBag from "@/helpers/error-bag";
import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";

const login = {
  namespaced: true,
  state: {
    loading: false,
    errors: new ErrorBag(),
    resMessage: null,
    form: {
      email: null,
      password: null,
      remember: false,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setResMessage: (state, response) => {
      state.resMessage = {
        code: response.code,
        message: response.title,
      };
    },
    setErrors: (state, errors) => {
      const error = errors.response.data.error;
      state.errors.setErrors(error.errors);
      state.resMessage = {
        code: error.code,
        message: error.title,
      };
    },
  },
  actions: {
    login: async ({ commit, state }, url) => {
      const form = new FormData();
      state.loading = true;

      if (state.errors.hasErrors()) {
        state.errors.clearAll();
      }

      _.forIn(state.form, function (value, key) {
        if (_.isBoolean(value)) {
          form.append(key, value);
        } else {
          if (!_.isEmpty(value)) {
            form.append(key, value);
          }
        }
      });

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          state.loading = false;
          commit("setResMessage", { code: 200, title: "Login success" });
        })
        .catch((err) => {
          state.loading = false;
          state.form.password = null;
          commit("setErrors", err);
        });
    },
  },
};

export default login;
