import axios from "axios";
import _ from "lodash";
import PaginationResource from "../../models/PaginationResource";
import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";

const companyType = {
  namespaced: true,
  state: {
    companyTypes: new PaginationResource(),
    companyType: {
      visible: true,
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateCompanyTypes: (state, list) => state.companyTypes.setResource(list),
    setCompanyType: (state, data) => (state.companyType = data),
  },
  actions: {
    loadCompanyTypes: async ({ commit, rootState }, page = 1) => {
      rootState.refresh = false;
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const companyTypes = await axios
        .get("/admin/company-types/list", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(companyTypes)) {
        commit("updateCompanyTypes", companyTypes);
      }
    },
    showCompanyType: async ({ commit, rootState }, id) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.editing = true;

      const companyType = await axios
        .get(`/admin/company-types/${id}`)
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(companyType)) {
        commit("setCompanyType", companyType);
      }
      $("#companyType").modal("show");
    },
    createNew: ({ commit, rootState }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.editing = false;
      commit("setCompanyType", { visible: true });
      $("#companyType").modal("show");
    },
    setVisible: async ({ commit }, type) => {
      await axios
        .patch(`/admin/company-types/${type.id}/set-visible`, {
          visible: !type.visible,
        })
        .then((res) => {
          commit(
            "setResMessage",
            { code: 200, title: "Company type has beed updated!" },
            { root: true }
          );
        })
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;

      let url = "/admin/company-types";
      let form = new FormData();

      const companyType = _.omit(state.companyType, ["id"]);

      if (rootState.editing) {
        url = `${url}/${state.companyType.id}`;
        form.append("_method", "PATCH");
      }

      form = objectToForm(companyType, form);

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Company type saved!!!" },
            { root: true }
          );
          $("#companyType").modal("hide");
          dispatch("loadCompanyTypes");
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default companyType;
