class ErrorBag {
    constructor(errors = []) {
        this.setErrors(errors);
    }

    hasErrors() {
        return !!this.keys.length;
    }

    get keys() {
        return Object.keys(this.errors);
    }

    hasError(key) {
        return this.keys.indexOf(key) > -1;
    }

    firstKey() {
        return this.keys[0];
    }

    first(key) {
        return this.errors[key] ? this.errors[key] : undefined;
    }

    all() {
        let errors = document.createElement("ul");

        Object.keys(this.errors).forEach(error => {
            let item = document.createElement("li");
            item.innerHTML = this.errors[error];
            errors.appendChild(item);
        });

        return errors;
    }

    setErrors(errors) {
        const errorValues = errors.reduce(
            (obj, item) => ((obj[item.title] = item.message), obj),
            {}
        );
        this.errors = errorValues;
    }

    clearAll() {
        this.setErrors([]);
    }

    clear(key) {
        return delete this.errors[key];
    }
}

export default ErrorBag;
