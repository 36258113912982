import { getField, updateField } from "vuex-map-fields";
import PaginationResource from "../../models/PaginationResource";
import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";

const property = {
  namespaced: true,
  state: {
    list: new PaginationResource(),
    additionals: {
      general: [],
      list: [],
      multipleLanguage: [],
    },
    dataTypes: [],
    id: null,
    slug: null,
    data_type: "",
    type: null,
    translation: {},
    visible: true,
    is_required: false,
    is_unique: false,
    multiple_language: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateProperties: (state, list) => state.list.setResource(list),
    updateAdditionalProperties: (state, list) => (state.additionals = list),
    updateType: (state, type) => (state.type = type),
    updateDataType: (state, data) => (state.dataTypes = data),
    resetOption: (state) => (state.option = []),
    setMultipleLanguage: (state, multiLanguage) =>
      (state.multiple_language = multiLanguage),
    resetListOption: (state) =>
      _.forEach(state.translation, (value, key) => (value.data = [])),
  },
  actions: {
    loadProperties: async ({ commit, rootState, state }, page = 1) => {
      const url = `/admin/properties/${state.type}/list`;

      rootState.refresh = false;
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const properties = await axios
        .get(url, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(properties)) {
        commit("updateProperties", properties);
      }
    },
    additionalProperties: async ({ commit, rootState, state }, page = 1) => {
      const url = `/additional-properties`;

      rootState.refresh = false;
      const params = {
        page: page,
        type: state.type,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const properties = await axios
        .get(url, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(properties)) {
        let additionalProperties = {};

        additionalProperties.multipleLanguage = _.filter(
          properties.data,
          (property) => {
            return (
              property.multiple_language &&
              (property.data_type != "list" || property.data_type != "option")
            );
          }
        );

        additionalProperties.general = _.filter(properties.data, (property) => {
          return !property.multiple_language && property.data_type != "list";
        });

        additionalProperties.list = _.filter(properties.data, (property) => {
          return property.data_type === "list";
        });

        additionalProperties.general = _.map(
          additionalProperties.general,
          (list) => {
            return {
              id: list.id,
              slug: list.slug,
              data_type: list.data_type,
              type: list.type,
              translation: _.keyBy(list.translation, "locale"),
            };
          }
        );

        additionalProperties.list = _.map(additionalProperties.list, (list) => {
          return {
            id: list.id,
            slug: list.slug,
            type: list.type,
            translation: _.keyBy(list.translation, "locale"),
          };
        });

        commit("updateAdditionalProperties", additionalProperties);
      }
    },
    editProperty: async ({ commit, rootState, state }, id) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.editing = true;

      const property = await axios
        .get(`/admin/properties/${state.type}/${id}`)
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(property)) {
        if (property.data_type == "option" || property.data_type == "list") {
          rootState.isVisibile = true;
          state.multiple_language = false;
        } else {
          rootState.isVisibile = false;
          state.multiple_language = property.multiple_language;
        }

        state.id = property.id;
        state.data_type = property.data_type;
        // state.option = property.option;
        state.visible = property.visible;
        state.is_required = property.is_required;
        state.translation = _.keyBy(property.translation, "locale");
      }
    },
    setField: async ({ commit, state }, { property, field }) => {
      const data = {
        field: {},
      };
      data.field[field] = !property[field];

      await axios
        .patch(`/admin/properties/${state.type}/${property.id}/set-field`, data)
        .then((res) => {
          commit(
            "setResMessage",
            { code: 200, title: "Property has been updated!" },
            { root: true }
          );
        })
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });
    },
    reorderProperty: async ({ commit, state }, ids) => {
      await axios
        .patch(`/admin/properties/${state.type}/reorder`, { ids: ids })
        .then((res) => {
          commit(
            "setResMessage",
            { code: 200, title: "Property position has been updated!" },
            { root: true }
          );
        })
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;

      let url = `/admin/properties/${state.type}`;
      let form = new FormData();

      const property = _.omit(state, [
        "id",
        "slug",
        "list",
        "dataTypes",
        "additionals",
      ]);

      if (rootState.editing) {
        url = `${url}/${state.id}`;
        form.append("_method", "PATCH");
      }

      form = objectToForm(property, form);

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Property has been saved!!!" },
            { root: true }
          );
          $("#propertyModal").modal("hide");
          dispatch("loadProperties");
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default property;
