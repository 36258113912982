import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import PaginationResource from "../../models/PaginationResource";

const manufacturer = {
  namespaced: true,
  state: {
    id: null,
    name: null,
    location: {
      address: null,
      lat: null,
      lng: null,
    },
    size: null,
    num_of_employee: null,
    founded_in: null,
    certificates: [],
    translation: {},
    properties: {},
    youtube_link: null,
    image: null,
    manufacturers: new PaginationResource(),
  },
  getters: {
    getField,
    getManufacturerImage: (state) => state.image,
  },
  mutations: {
    updateField,
    updateManufacturers: (state, list) => state.manufacturers.setResource(list),
    updateLocation: (state, location) => (state.location = location),
    updateYoutubeLink: (state, link) => (state.youtube_link = link),
    removeCertificate: (state, index) => state.certificates.splice(index, 1),
  },
  actions: {
    loadManufacturers: async ({ commit, rootState, state }, page = 1) => {
      const params = {
        page: page,
      };
      const url = rootState.waitingList
        ? "/admin/manufacturer-requests/list"
        : "/admin/manufacturers/list";

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const manufacturers = await axios
        .get(url, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(manufacturers)) {
        commit("updateManufacturers", manufacturers);
      }
    },
    loadManufacturer: async ({ commit, rootState, dispatch }, id = null) => {
      let url = "";

      if (!_.isEmpty(id)) {
        url = rootState.waitingList
          ? `/admin/manufacturer-requests/${id}`
          : `/admin/manufacturers/${id}`;
      } else {
        url = rootState.waitingList
          ? "/manufacturer/info/show-draft"
          : "/manufacturer/info/show";
      }

      rootState.refresh = false;
      const manufacturer = await axios
        .get(url)
        .then((res) => res.data.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(manufacturer)) {
        dispatch("setData", manufacturer);
      }
    },
    setData: ({ commit }, data) => {
      commit(
        "setState",
        { module: "manufacturer", data: _.omit(data, ["founder"]) },
        { root: true }
      );
      commit(
        "setState",
        { module: "founder", data: data.founder },
        { root: true }
      );
    },
    destroyImage: ({ commit, state }, index) => {
      commit("removeCertificate", index);
    },
    submit: ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;
      let url =
        rootState.isAdmin && rootState.waitingList
          ? `/admin/manufacturer-requests/${state.id}/approve`
          : "/manufacturer/info";

      let form = new FormData();
      let founder = {};

      const manufacturer = _.omit(state, [
        "id",
        "certificates",
        "image",
        "manufacturers",
      ]);

      if (!_.isEmpty(_.toString(state.id))) {
        if (!rootState.isAdmin) {
          url = `${url}/${state.id}`;
        }

        form.append("_method", "PATCH");
        founder = _.omit(rootState.founder, ["profile_picture"]);
      } else {
        founder = _.omit(rootState.founder, ["id", "profile_picture"]);
      }

      form = objectToForm(manufacturer, form);
      form = objectToForm(founder, form, "founder");
      if (!_.isEmpty(rootState.images)) {
        let images = _.omit(rootState.images, ["certificates"]);
        form = objectToForm(images, form);
      }

      axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data;
          if (!_.isEmpty(data)) {
            dispatch("setData", data);
          }

          if (!_.isEmpty(rootState.images.certificates)) {
            dispatch("uploadCertificate");
          } else {
            if (rootState.isAdmin && rootState.waitingList) {
              dispatch("destroyCertificate");
            } else {
              rootState.loading = false;
              commit(
                "setResMessage",
                { code: 200, title: "Manufacturer Info saved" },
                { root: true }
              );
            }
          }
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
    uploadCertificate: ({ commit, state, rootState, dispatch }) => {
      const url =
        rootState.isAdmin && rootState.waitingList
          ? `/admin/manufacturer-requests/${state.id}/upload-certificates`
          : `/manufacturer/info/${state.id}/certificates`;

      const form = objectToForm(
        rootState.images.certificates,
        new FormData(),
        "images"
      );

      axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          dispatch("loadManufacturer");

          commit(
            "setResMessage",
            { code: 200, title: "Manufacturer Info saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
    destroyCertificate: async ({ commit, state, rootState, dispatch }) => {
      const url = `/admin/manufacturer-requests/${state.id}/delete-certificates`;

      await axios
        .delete(url)
        .then((res) => {
          rootState.loading = false;
          dispatch("loadManufacturer");

          commit(
            "setResMessage",
            { code: 200, title: "Manufacturer Info saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default manufacturer;
