import PaginationResource from "../../models/PaginationResource";
import { getField, updateField } from "vuex-map-fields";

const notification = {
  namespaced: true,
  state: {
    notifications: new PaginationResource(),
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateNotifications: (state, list) => state.notifications.setResource(list),
  },
  actions: {
    loadNotifications: async ({ commit, rootState }, page = 1) => {
      const url = rootState.isAdmin
        ? "/admin/notifications"
        : "/manufacturer/notifications";

      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const list = await axios
        .get(url, { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(list)) {
        commit("updateNotifications", list);
      }
    },
  },
};

export default notification;
