import { getField, updateField } from "vuex-map-fields";
import objectToForm from "@/helpers/form-data";
import axios from "axios";
import _ from "lodash";

const privacyPolicy = {
  namespaced: true,
  state: {
    id: null,
    translation: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateId: (state, id) => (state.id = id),
    updateTranslations: (state, translations) =>
      (state.translation = translations),
  },
  actions: {
    setData: ({ commit }, data) => {
      commit("updateId", data.id);
      commit("updateTranslations", _.keyBy(data.translations, "locale"));
    },
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }

      rootState.loading = true;

      let url = `/admin/privacy-policy`;
      let form = new FormData();
      form.append("_method", "PATCH");

      const blog = _.omit(state, ["id"]);
      form = objectToForm(blog, form);

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const data = res.data.data;
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 200, title: "Privacy Policy saved" },
            { root: true }
          );
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default privacyPolicy;
