import axios from "axios";
import _ from "lodash";
import PaginationResource from "../../models/PaginationResource";

const country = {
  namespaced: true,
  state: {
    countries: new PaginationResource(),
  },
  getters: {},
  mutations: {
    updateCountries: (state, list) => state.countries.setResource(list),
  },
  actions: {
    loadCountries: async ({ commit, rootState }, page = 1) => {
      const params = {
        page: page,
      };

      if (!_.isEmpty(rootState.search)) {
        params.q = rootState.search;
      }

      const countries = await axios
        .get("/admin/countries/list", { params })
        .then((res) => res.data)
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });

      if (!_.isEmpty(countries)) {
        commit("updateCountries", countries);
      }
    },
    setCountry: async ({ commit }, country) => {
      await axios
        .patch(`/admin/countries/${country.id}`, {
          enabled: !country.enabled,
        })
        .then((res) => {
          commit(
            "setResMessage",
            { code: 200, title: "Country has beed updated!" },
            { root: true }
          );
        })
        .catch((err) => {
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default country;
