import axios from "axios";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";

const requestTags = {
  namespaced: true,
  state: {
    productId: null,
    num_of_tags: null,
    size: "2x2cm",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setNumOfTags: (state, numTag) => (state.num_of_tags = numTag),
    setProductId: (state, productId) => (state.productId = productId),
  },
  actions: {
    submit: async ({ commit, state, rootState, dispatch }) => {
      if (rootState.errors.hasErrors()) {
        rootState.errors.clearAll();
      }
      rootState.loading = true;

      const url = `/manufacturer/request-tag/${state.productId}`;
      let form = new FormData();
      form.append("num_of_tags", state.num_of_tags);
      form.append("size", state.size);

      await axios
        .post(url, form, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          rootState.loading = false;
          commit(
            "setResMessage",
            { code: 201, title: "Request Tags send to admin" },
            { root: true }
          );
          commit("setNumOfTags", null);
          $("#requestTag").modal("hide");
        })
        .catch((err) => {
          rootState.loading = false;
          commit("setErrors", err, { root: true });
        });
    },
  },
};

export default requestTags;
